import { graphql } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ArtistCaption from '../components/artists/caption';
import ArtistListItem from '../components/artists/list-item';
import Pager from '../components/utils/pager';
import { AppContext, ThemeColor } from '../context/context';

import '../css/artists.css';
import { PATH } from '../helper/config';

const Artist: React.FunctionComponent<any> = ({ data, pageContext }) => {
  const [list, setList] = useState([...data.allWpGqlArtistItem.edges]);
  const context = useContext(AppContext);

  useEffect(() => {
    context.setLanguage(
      {
        ja: `${PATH.ARTISTS}`,
        en: `/en${PATH.ARTISTS}`
      },
      pageContext.locale
    );

    context.setTheme(ThemeColor.GRAY);

    // const tempList = [...data.allWpGqlArtistItem.edges];
    // tempList.splice(3 + Math.round(Math.random() * (tempList.length - 3)), 0, { isFree: true });
    // setList([...tempList]);

    return () => {
      context.setTheme(ThemeColor.DEFAULT);
    };
  }, []);

  return (
    <div className="artists util__page">
      <Helmet>
        <title>ARTISTS | POST-FAKE</title>
        <meta property="og:title" content={`ARTISTS | POST-FAKE`} />
        <meta name="twitter:site" content={`ARTISTS | POST-FAKE`} />
        <meta name="twitter:title" content={`ARTISTS | POST-FAKE`} />
        <meta property="og:url" content={`https://postfake.com${context.localePath}${PATH.ARTISTS}`} />
      </Helmet>
      <div className="artists__list">
        <div className="artists__list-inner">
          <ArtistCaption />
          {list &&
            list.map((elem: any, i: number) => {
              return <ArtistListItem key={`artist-list-item${i}`} data={elem}></ArtistListItem>;
            })}
          <ArtistSoon />
          <ArtistSoon />
          <ArtistSoon />
          <ArtistSoon />
          <ArtistSoon />
        </div>
        <Pager context={pageContext} basePath={PATH.ARTISTS} path={PATH.ARTISTS_PAGE} />
      </div>
    </div>
  );
};
export default Artist;

export const query = graphql`
  query artistListQuery($skip: Int!, $limit: Int!, $locale: String!) {
    allWpGqlArtistItem(skip: $skip, limit: $limit, filter: { locale: { locale: { eq: $locale } } }, sort: { fields: date, order: DESC }) {
      edges {
        node {
          databaseId
          artist {
            name
            nameEnglish
            image {
              mediaItemUrl
              mediaDetails {
                height
                width
              }
            }
          }
        }
      }
    }
  }
`;

const ArtistSoon: React.FunctionComponent = () => {
  return (
    <div className="artists__list-item artists__list-item-soon ff-univ">
      <span>COMING SOON</span>
    </div>
  );
};
